#navbar-container {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  border-bottom: 2px solid #efefef;
}

#navbar-leading {
  display: flex;
  white-space: nowrap;
}

#navbar-trailing {
  margin-left: 20px;
}

#wallet-container {
  border: solid 1px #22a85b33;
  border-radius: 12px;
  height: 56px;
  display: flex;
  padding: 0 8px;
  align-items: center;
}
#credit-container {
  border: solid 1px rgba(50, 117, 252, 0.2);
  border-radius: 12px;
  height: 56px;
  display: flex;
  padding: 0 8px;
  align-items: center;
}

/* style for notification */
#current-user-container:not(.has-avatar) .ant-avatar > img,
#wallet-container .ant-avatar > img,
#notification-container .ant-avatar > img {
  object-fit: none;
}
#current-user-container:not(.has-avatar) .ant-avatar > img,
#credit-container .ant-avatar > img,
#notification-container .ant-avatar > img {
  object-fit: none;
}

#current-user-container {
  background-color: #f9f9f9;
  width: 200px;
  border-radius: 12px;
  padding: 8px;
  padding-right: 14px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#current-user-container.ant-dropdown-open {
  background-color: #f9f9f9;
  border-radius: 12px;
}

.user-icon {
  background-color: #3275fc !important;
}

.wallet-icon {
  background-color: #22a85b33 !important;
}
.credit-icon {
  background-color: rgba(251, 214, 143, 0.5) !important;
}

.noti-icon {
  background-color: #f7f9fb !important;
}

#notification-container .ant-badge-multiple-words {
  padding: 0 4px;
}

#notification-container .ant-badge-count {
  box-shadow: 0 0 0 2px white;
}

#notification-container .ant-badge-count,
#notification-container .ant-badge-count * {
  font-weight: 700;
}

#notification-container .ant-badge-dot {
  width: 12px;
  height: 12px;
}

#amount-title {
  font-family: Prompt;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.balance-bath {
  color: var(--primary-green);
}
.balance-credit {
  color: #fdc56e;
}
#amount-desc {
  font-family: Prompt;
  line-height: 12px;
  font-size: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.username-title {
  color: var(--medium-black);
  white-space: nowrap;
  font-weight: 700;
  line-height: 17.5px;
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-role-title {
  color: var(--primary-blue);
  font-weight: 12px;
  line-height: 15px;
  font-weight: 700;
}

.user-profile-dropdown .ant-dropdown-menu {
  border-radius: 12px;
  padding: 15px 0;
}

.user-profile-dropdown .ant-dropdown-menu-item {
  padding: 8px 14px;
}

.user-profile-dropdown .ant-dropdown-menu .dropdown-menu-title {
  color: var(--font-darkgray);
}

.user-profile-dropdown #logout-menu {
  color: #f46436;
}

.notification-overlay .ant-popover-inner {
  border-radius: 16px;
  overflow: hidden;
  width: 450px;
}

.notification-overlay .ant-popover-inner-content {
  padding: 0;
  width: 100%;
}

.notification-overlay .ant-popover-arrow-content:before {
  background-color: var(--primary-blue);
}

.notification-overlay #notification-header {
  background: var(--primary-blue);
  padding: 22px;
  color: white;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.notification-overlay #notification-header .notification-tool {
  color: white;
  font-size: 18px;
}

.notification-overlay #empty-noti-container {
  padding: 22px;
}

.notification-item-container {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.more-noti-button:hover,
.notification-item-container:hover {
  background-color: var(--hover-color);
}

.flag-read {
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  background-color: var(--primary-blue);
}

.flag-read-temp {
  width: 10px;
  height: 10px;
}

.more-noti-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
}

#notification-body {
  max-height: 400px;
  overflow-y: auto;
}
