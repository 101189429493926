:root {
  --primary-color: white;
  --white-color: white;
  --background-color: #f4f4f4;
  --primary-blue: #3275fc;
  --medium-black: #5b5b6b;
  --primary-green: #22a85b;
  --color-shuffle: #f7a823;
  --hover-primary-green: #22c468;
  --font-darkgray: #454456;
  --font-lightgray: #b7b9b8;
  --hover-color: #f5f8ff;
  --title-color: #939393;
  --danger-color: #f46436;
  --line-grey: #efefef;
  --btn-grey: #ededed;
  --button-blue: #7da8ff;
  --border-gray: #d6d6d6;
  --text-wallet: #93a7ba;

  --height-slider-bar: 22px;
  --radius-model: 16px;

  --font-mulish: "Mulish";
  --font-prompt: "Prompt";

  --width-child: 43px;
}
