.preview-file-wrapper {
  height: 100%;
  width: 100%;
  padding: 34px;
}
.preview-file .ant-btn.ant-btn-loading:before {
    display: contents;
}


.preview-file-card {
  box-shadow: 0px 0px 10px rgba(0, 31, 112, 0.08);
  border-radius: 12px;
  background-color: #ffffff;
  padding: 29px;
  /* min-width: 860px; */
  max-width: 650px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

#powered-by {
  text-align: center;
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--font-lightgray);
}
