#sidebar-container {
  width: 264px;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#sidebar-container #footer-label-contaner {
  border-top: 1px solid #efefef;
}

#sidebar-container .footer-label-detail {
  text-align: left;
  padding-top: 15px;
  padding-left: 25px;
  padding-bottom: 15px;
  width: 200px;
}

#sidebar-container .footer-label-detail .company-label {
  color: #5b5b6b;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}

#sidebar-container .footer-label-detail .company-product-description {
  color: #a0a5b2;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

#sidebar-menu-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px 27px;
  border-bottom: 1px solid #efefef;
}

.nav-button {
  background-color: #f9f9f9;
  height: 74px;
  width: 200px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  gap: 10px;
}

.nav-button-icon-container {
  background-color: #efefef;
  height: 42px;
  width: 42px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.04em;
  margin-bottom: 4px;
  color: #121f3e;
}

.nav-button-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #5b5b6b;
}

.arrow-right-button {
  background-color: #5b5b6b;
  mask-image: url(/public/images/common/arrow-left.svg);
  mask-repeat: no-repeat;
  width: 15px;
  height: 15px;
  mask-size: contain;
  transform: rotate(180deg);
  margin-left: 3px;
}

.nav-button-icon {
  width: 20px;
  height: 20px;
  background-color: #b7b9b8;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.folder-icon {
  mask-image: url(/public/images/sidebar/folder-icon.svg);
}
.shared-with-me-icon {
  mask-image: url(/public/images/sidebar/profile-2user.svg);
}
.rewards-icon {
  mask-image: url(/public/images/sidebar/my-rewards-icon.svg);
}

/* active style */

.nav-button.active {
  background-color: var(--primary-blue);
}

.nav-button.active .nav-button-title,
.nav-button.active .nav-button-subtitle {
  color: white;
}

.nav-button.active .nav-button-icon-container,
.nav-button.active .arrow-right-button {
  background-color: white;
}

.nav-button.active .nav-button-icon {
  background-color: var(--primary-blue);
}

#wallet-sidebar-menu-container {
  flex: 1;
  padding: 25px 27px 0 27px;
}

#wallet-sidebar-container {
  background-color: #22a85b26;
  border-radius: 14px;
  padding: 17px;
}

#wallet-add-icon {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 20px;
}

#wallet-add-icon > img {
  width: 24px;
  height: 24px;
}

#topup-button {
  cursor: pointer;
  background-color: #22a85b;
  width: 100%;
  height: 59px;
  border-radius: 14px;
  display: flex;
  align-items: center;
}

#balance-title {
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #464646;
}

#balance-value {
  font-family: Prompt;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary-green);
  margin-top: 3px;
  margin-bottom: 5px;
}

#topup-title {
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  color: white;
  flex: 1;
}
