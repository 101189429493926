@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;700;800&display=swap");

.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: var(--font-darkgray);
}

#content-container {
  overflow-y: auto;
  flex: 1;
}
.unauthen-screen {
  background-color: white;
}

#content-container.projects-screen,
#content-container.shared-with-me-screen,
#content-container.rewards-screen,
#content-container.reward-screen {
  padding: 20px;
}

.pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}
.primary-blue {
  color: var(--primary-blue) !important;
}
.primary-green {
  color: var(--primary-green) !important;
}

.lightgray {
  color: var(--font-lightgray);
}

.darkgray {
  color: var(--font-darkgray);
}

.medium-black {
  color: var(--medium-black);
}

.bold {
  font-weight: 700;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  border: none !important;
}

.ant-pagination-prev button:hover,
.ant-pagination-next button:hover {
  color: black !important;
}

.ant-pagination-item {
  border: none !important;
}

.ant-pagination-item-active {
  background-color: #f1f1f1 !important;
  border-radius: 6px !important;
}

.ant-pagination-item a {
  color: var(--font-lightgray) !important;
  font-size: 18px;
  font-weight: 600;
}

.ant-pagination-item-active a {
  color: var(--font-darkgray) !important;
}

.ant-table-pagination.ant-pagination {
  margin-top: 42px !important;
  margin-bottom: 0 !important;
}

.unselectable {
  /* Safari */
  -webkit-user-select: none;
  /* Firefox */
  -moz-user-select: none;
  /* IE10+/Edge */
  -ms-user-select: none;
  /* Standard */
  user-select: none;
}

.ant-btn-primary:not(:hover) {
  background-color: var(--primary-blue) !important;
}

.ant-btn-primary:disabled {
  border: none;
  background-color: #3275fc66 !important;
  color: white !important;
}

.ant-btn-link {
  color: var(--title-color) !important;
}

.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}
.ant-input-affix-wrapper-disabled,
.ant-picker.ant-picker-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input[disabled] {
  background-color: #ececec33 !important;
}

button.ant-btn.ant-btn-success {
  background-color: var(--primary-green);
  color: white;
  border: none;
}

#app-body-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.box-container {
  box-shadow: 6px 15px 48px 0px #001f700a;
}

#content-container.projects-screen > *,
#content-container.shared-with-me-screen > *,
#content-container.rewards-screen > *,
#content-container.reward-screen > * {
  min-width: 900px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
/* 
#content-container.rewards-screen.create-screen > * {
} */

#global-loading {
  z-index: 10000;
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select.select-recode div .ant-select-dropdown {
  width: 500px !important;
}
