div.topup-modal-wrapper div.ant-modal-content {
  width: 74vw !important;
  min-width: 74vw !important;
  min-height: 617px !important;
  max-height: 617px !important;
}

div.topup-modal-wrapper div.ant-modal-content .ant-modal-body {
  padding: 73px 159px 78px 159px;
}

div#topup-modal-content-container {
  /* padding: 73px 159px 78px 159px; */
  height: 100%;
  width: 100%;
}

div#topup-modal-content-container div#progress-tab-container {
  margin-bottom: 55px;
}

div#topup-modal-content-container
  div#progress-tab-container
  > *:not(:last-child) {
  margin-right: 4px;
}

div#topup-modal-content-container .progress-tab {
  width: 20%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #d6d6d6;
  padding-bottom: 6px;
  border-bottom: 4px solid #d6d6d6;
}

div#topup-modal-content-container .progress-tab.current {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--primary-green);
  border-bottom: 4px solid var(--primary-green);
}

div#topup-modal-content-container div#balance-input-container {
  margin-bottom: 39px;
}

div#topup-modal-content-container div#current-balance-container {
  padding: 15px 20px 19px 20px;
  border: 1px solid #454456;
  border-radius: 14px;
  width: 35%;
  margin-right: 14px;
}

div#topup-modal-content-container div#current-balance-container > div.label {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #454456;
}

div#topup-modal-content-container div#current-balance-container > div.amount {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #454456;
}

div#topup-modal-content-container .transfer-amount-text-input input {
  height: 83px;
  border: 1px solid #d6d6d6;
  border-radius: 14px;
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;

  color: #d6d6d6;
}

div#topup-modal-content-container
  .transfer-amount-text-input
  input.ant-input::placeholder {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #d6d6d6;
}

div#topup-modal-content-container div.input-description {
  font-style: italic;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #b7b9b8;
  margin-top: 8px;
}

div#topup-modal-content-container div#total-topup-conclude-container {
  padding: 45px 27px 9px 35px;
  border: 1px solid #f1f1f1;
  border-radius: 14px;
  margin-bottom: 49px;
}

div#topup-modal-content-container
  div#total-topup-conclude-container
  > div.label {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130.2%;
  color: #d6d6d6;
}

div#topup-modal-content-container
  div#total-topup-conclude-container
  > div.amount {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 71px;
  text-align: right;
  color: #22a85b;
}

div#topup-modal-content-container
  div#topup-modal-footer-container
  > div#footer-button-list
  > *:not(:last-child) {
  margin-right: 8px;
}

div#topup-modal-content-container div#cancel-button,
div#topup-modal-content-container div#next-button {
  padding: 15px 58px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

div#topup-modal-content-container div#next-button {
  color: white;
  background: var(--primary-blue);
  border-radius: 12px;
}

div#topup-modal-content-container div#cancel-button {
  color: #b7b9b8;
  border: 1px solid #b7b9b8;
  border-radius: 12px;
}

div#topup-modal-content-container div#cancel-button:hover,
div#topup-modal-content-container div#next-button:hover {
  opacity: 0.8;
}

div#topup-modal-content-container div#cancel-button:active,
div#topup-modal-content-container div#next-button:active {
  opacity: 1;
}

div#topup-modal-content-container div#secure-payment-container {
  margin: -30px 0px 15px 0px;
}

div#topup-modal-content-container div#topup-amount-label {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #22a85b;
}

div#topup-modal-content-container div#qr-topup-img-container {
  margin-bottom: 17px;
}

div#topup-modal-content-container div#qr-topup-img-container > img {
  width: 200px;
  height: auto;
}

div#topup-modal-content-container div#waiting-payment-countdown {
  font-style: italic;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #93a7ba;
  margin-bottom: 16px;
}

div#topup-modal-content-container
  div#waiting-payment-countdown
  > span.count-timer {
  color: #5b5b6b;
}

div#topup-modal-content-container div#biller-id-reference-number-container {
  font-style: italic;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #93a7ba;
  white-space: pre;
  margin-bottom: 38px;
}

div#topup-modal-content-container
  div#biller-id-reference-number-container
  > *:not(:last-child) {
  padding-right: 11px;
  margin-right: 11px;
  border-right: 1px solid #e6eaee;
}

div#topup-modal-content-container
  div#biller-id-reference-number-container
  > div.additional-detail
  > div.value {
  color: var(--primary-blue);
}

div#topup-modal-content-container
  div#change-top-up-cancel-container
  > *:not(:last-child) {
  margin-right: 24px;
}

div#topup-modal-content-container div#change-top-up-amount-button {
  background: #3275fc;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 15px 39px;
}

div#topup-modal-content-container div#change-top-up-amount-button:hover {
  opacity: 0.8;
}

div#topup-modal-content-container div#change-top-up-amount-button:active {
  opacity: 1;
}

div#topup-modal-content-container div#loader-container {
  margin-top: 120px;
  margin-bottom: 65px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

div#topup-modal-content-container div#loader-container > img {
  animation: spin 1s linear infinite;
}

div#topup-modal-content-container div#please-wait-label {
  font-style: italic;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #93a7ba;
}

div#topup-modal-content-container div#topup-success-img-container {
  margin-top: 88px;
  margin-bottom: 46px;
}

div#topup-modal-content-container div#topup-successful-label {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #22a85b;
  margin-bottom: 20px;
}

div#topup-modal-content-container .success-page {
  margin-bottom: 60px !important;
}

div#topup-modal-content-container div#publish-now-button {
  background: var(--primary-blue);
  border-radius: 12px;
  padding: 15px 60px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

div#topup-modal-content-container div#publish-later-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #b7b9b8;
}

div#topup-modal-content-container div#publish-now-button:hover,
div#topup-modal-content-container div#publish-later-button:hover {
  opacity: 0.8;
}

div#topup-modal-content-container div#publish-now-button:active,
div#topup-modal-content-container div#publish-later-button:active {
  opacity: 1;
}
