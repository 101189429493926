.image-box-voice .border-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

}

.image-box-voice .border-box .text-description {
  color: #f46436;
}

.image-box-voice .border-box .wrap-video {
  position: relative;
  width: 100%;
  height: 300px;
}

.image-box-voice .border-box .wrap-player {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 40px;
  height: auto;
  background-color: #f8f8f8;
}

.image-box-voice .border-box .wrap-player-complete {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100px;
  background-color: #f8f8f8;
}



.image-box-voice .border-box .wrap-video .video-js {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #B7B9B8;
  position: relative;
  padding: 0;
  width: 100%;
  border-radius: 20px;
  height: 300px;
}

.image-box-voice .border-box .wrap-video .wrap-video-guard {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #B7B9B8;
  position: absolute;
  padding: 0;
  width: 100%;
  border-radius: 20px;
  height: 300px;
  z-index: 99;
}

.cursor-pointer { 
  cursor: pointer;
}

.icon-play {
  margin-left: 10px;
  margin-right: 10px;
}


.audio-recorder {
  display: none !important;
}

.playing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #44DC82;
  font-size: 16px;
  font-weight: bolder;
}

audio {
  display: none;
}

.button-device-error {
  padding: 10px;
  background-color: #B7B9B8;
  border-radius: 10px;
  color: #fff;
}

.error-modal .ant-modal-close-x {
  display: none;
}

.error-modal .ant-modal-content {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 20px;
}

.error-modal .ant-modal-body {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0px;
  display: flex;
}

.error-modal .ant-modal {
  width: 320px !important;
  height: auto;
}