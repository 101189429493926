.custom-modal {
  text-align: center;
}

.custom-modal .ant-modal-content {
  width: 450px !important;
  height: 465px !important;
}

.custom-modal .ant-modal-close,
.custom-modal .ant-modal-close-x {
  display: none !important;
}

.custom-modal .ant-modal-content {
  border-radius: 24px;
}

.custom-modal button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  color: white;
  border: none;
}

.custom-modal .ant-btn-primary:not(:hover) {
  background-color: var(--primary-blue) !important;
}

.custom-modal .ant-btn-success {
  background-color: var(--primary-green);
}

.custom-modal .ant-btn-success:hover {
  background-color: var(--hover-primary-green);
  color: white;
}

.custom-modal .ant-btn-success:focus {
  background-color: var(--hover-primary-green);
  color: white;
}

.custom-modal-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-modal .ant-modal-body {
  height: 100%;
  padding: 38px 41px;
}

.project-modal-content-container {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: var(--font-darkgray);
  padding: 12px;
}

.custom-modal-content-container .description-modal-content-container {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-darkgray);
  font-size: 18px;
}

.project-modal-content-container .project-modal-title {
  font-size: 16px;
  font-weight: 700;
}

.project-modal-content-container .project-modal-earn {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-green);
}

.project-modal-content-container .project-modal-describe {
  font-size: 16px;
  font-weight: 500;
}

/* confirm modal style */

.confirm-modal-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.confirm-modal-content-container .confirm-modal-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #454456;
}

.confirm-modal-content-container .confirm-modal-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 20px;
}

.confirm-modal-content-container .accept-button,
.confirm-modal-content-container .accept-button:hover {
  background-color: var(--primary-green);
  color: white;
}

.confirm-modal-content-container .cancel-button,
.confirm-modal-content-container .cancel-button:hover {
  background-color: #b7b9b8;
  color: white;
}

.topup-modal-wrapper {
  width: 74vw !important;
  min-width: 74vw !important;
  min-height: 617px !important;
  max-height: 617px !important;
}

/* div.topup-modal-wrapper div.ant-modal-content {
  width: 100% !important;
  height: 100% !important;
} */

div.topup-modal-wrapper div.ant-modal-body {
  padding: 0px;
}
